import React from 'react';
import Helmet from 'react-helmet';

const App = ({ element }) => {
  return (
    <div>
      <Helmet />
      {element}
    </div>
  );
};
export default App;
