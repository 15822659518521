// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-crisis-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/crisis.js" /* webpackChunkName: "component---src-pages-crisis-js" */),
  "component---src-pages-essays-dpc-solves-the-healthcare-crisis-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/essays/dpc-solves-the-healthcare-crisis.js" /* webpackChunkName: "component---src-pages-essays-dpc-solves-the-healthcare-crisis-js" */),
  "component---src-pages-essays-index-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/essays/index.js" /* webpackChunkName: "component---src-pages-essays-index-js" */),
  "component---src-pages-essays-the-healthcare-market-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/essays/the-healthcare-market.js" /* webpackChunkName: "component---src-pages-essays-the-healthcare-market-js" */),
  "component---src-pages-faq-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-faq-benefits-of-dpc-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/faq/benefits-of-dpc.js" /* webpackChunkName: "component---src-pages-faq-benefits-of-dpc-js" */),
  "component---src-pages-faq-dpc-for-healthy-people-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/faq/dpc-for-healthy-people.js" /* webpackChunkName: "component---src-pages-faq-dpc-for-healthy-people-js" */),
  "component---src-pages-faq-dpc-saves-you-money-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/faq/dpc-saves-you-money.js" /* webpackChunkName: "component---src-pages-faq-dpc-saves-you-money-js" */),
  "component---src-pages-faq-dpc-vs-concierge-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/faq/dpc-vs-concierge.js" /* webpackChunkName: "component---src-pages-faq-dpc-vs-concierge-js" */),
  "component---src-pages-faq-how-much-does-it-cost-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/faq/how-much-does-it-cost.js" /* webpackChunkName: "component---src-pages-faq-how-much-does-it-cost-js" */),
  "component---src-pages-faq-still-need-insurance-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/faq/still-need-insurance.js" /* webpackChunkName: "component---src-pages-faq-still-need-insurance-js" */),
  "component---src-pages-faq-what-is-dpc-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/faq/what-is-dpc.js" /* webpackChunkName: "component---src-pages-faq-what-is-dpc-js" */),
  "component---src-pages-faq-why-membership-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/faq/why-membership.js" /* webpackChunkName: "component---src-pages-faq-why-membership-js" */),
  "component---src-pages-index-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-transparency-surgery-centers-js": () => import("/Users/colinmcd94/Documents/Projects/dpcnation/src/pages/transparency/surgery-centers.js" /* webpackChunkName: "component---src-pages-transparency-surgery-centers-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/colinmcd94/Documents/Projects/dpcnation/.cache/data.json")

